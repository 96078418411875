
.main {
  padding-top: 72px;
  .tabs {
    .tabs-container {
      @media screen and (min-width: 1180px) {
        justify-content: center; } }
    .indicator {
      bottom: auto;
      top: 0;
      height: 4px;
      background-color: #444; }
    .lottery-tab {
      font-size: 1em !important;
      min-width: 110px;
      &:hover {
        background-color: #eee; }
      // !important is necessary?
      &.selected-lottery {
        color: #333 !important; } }

    &.selected-lotomania {
      .indicator {
        background-color: #f78101; } }
    &.selected-timemania {
      .indicator {
        background-color: #36b036; } }
    &.selected-supersete {
      .indicator {
        background-color: #A8D045; } }
    &.selected-megasena {
      .indicator {
        background-color: #269869; } }
    &.selected-loteca {
      .indicator {
        background-color: #fb1e02; } }
    &.selected-lotofacil {
      .indicator {
        background-color: #930a89; } }
    &.selected-federal {
      .indicator {
        background-color: #103099; } }
    &.selected-duplasena {
      .indicator {
        background-color: #a61324; } }
    &.selected-quina {
      .indicator {
        background-color: #260885; } }
    &.selected-diadesorte {
      .indicator {
        background-color: #edc48b; } } } }
