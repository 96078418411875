.next-round {
  margin-bottom: 2rem;
  .label {
    color: #666; }
  .date {
    font-size: 1.3rem;
    margin-top: .3rem;
    margin-bottom: 1rem; }
  .prize {
    font-size: 3rem;
    font-weight: bold; } }
