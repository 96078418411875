.number-list-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // flex-wrap: wrap
  width: 100%;
  margin: 2em 0;
  background-color: white;
  // background-color: cyan

  .number-row {
    display: flex;
    flex-wrap: wrap;

    .lottery-number {
      width: 2.2em;
      height: 2.2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      border-radius: 50%;
      font-size: 1.4rem;
      font-weight: 400;
      margin: .5rem .5rem;
      // font-family: Roboto
      font-weight: bold; } } }
