
.prizes-table-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;

  .lottery-results-table {
    font-weight: 400;
    color: #1a1a1a;
    max-width: 600px;
    min-width: 320px;
    width: 95%;

    .header {
      .header-cell {
        font-size: 1.3rem !important;
        color: #aaa;
        border-top: 1px solid #ccc;
        border-bottom: 3px solid #aaa; } }
    .body {
      .body-cell {
        font-size: 1.1rem !important; } } }

  // .lottery-results-table td:nth-child(1)
  //   text-align: left

  // .lottery-results-table td:nth-child(2)
  //   text-align: center

  // .lottery-results-table td:nth-child(3)
 }  //   text-align: right
