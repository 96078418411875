.tab-content {
  text-align: center;
  .section-title {
    text-transform: uppercase;
    padding: 1rem;
    color: #666; }

  .tabpanel-header {
    color: white;
    text-align: center; } }
