.loteca {
  .matches-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 3rem;

    .match-results-table {
      font-weight: 400;
      color: #1a1a1a;
      max-width: 600px;
      min-width: 320px;
      width: 95%;

      .body {
        .body-cell {
          font-size: 1.1rem !important; }
        .result {
          padding: 0 !important;
          min-width: 50px; }

        td.winner, span.winner {
          color: #fb1e02; } } } } }
