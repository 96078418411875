.content-winners-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  .winners {
    font-size: 1.5rem;
    margin-bottom: 1rem; }

  .sub-title {
    color: #666; }
  .prize-value {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem; } }
