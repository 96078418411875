* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body, #root {
  width: 100%;
  height: 100%;
  background-color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px; }

.w-100 {
  width: 100%; }

.error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: darkred; }
