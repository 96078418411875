// TODO colocar no style do app.js pode ser usado em varios componentes?

.caixa-primary-color {
  fill: #103099;
  background-color: #103099;
  color: #103099; }

.caixa-second-color {
  fill: #f78101;
  background-color: #f78101;
  color: #f78101; }

.megasena-primary-color {
  fill: #269869;
  background-color: #269869;
  color: #269869; }

.megasena-second-color {
  fill: #8fcbb3;
  background-color: #8fcbb3; }

.loteca-primary-color {
  fill: #fb1e02;
  background-color: #fb1e02;
  color: #fb1e02; }

.loteca-second-color {
  fill: #fc8e7f;
  background-color: #fc8e7f; }

.lotofacil-primary-color {
  fill: #930a89;
  background-color: #930a89;
  color: #930a89; }

.lotofacil-second-color {
  fill: #c87fc3;
  background-color: #c87fc3; }

.lotomania-primary-color {
  fill: #f78101;
  background-color: #f78101;
  color: #f78101; }

.lotomania-second-color {
  fill: #fabf7f;
  background-color: #fabf7f; }

.timemania-primary-color {
  fill: #36b036;
  background-color: #36b036;
  color: #36b036; }

.timemania-second-color {
  fill: #91dd91;
  background-color: #91dd91; }

.supersete-primary-color {
  fill: #A8D045;
  background-color: #A8D045;
  color: #A8D045; }

.supersete-second-color {
  fill: #D8EBA9;
  background-color: #D8EBA9;
  color: #D8EBA9; }

.federal-primary-color {
  fill: #103099;
  background-color: #103099;
  color: #103099; }

.federal-second-color {
  fill: #8797cb;
  background-color: #8797cb; }

.duplasena-primary-color {
  fill: #a61324;
  background-color: #a61324;
  color: #a61324; }

.duplasena-second-color {
  fill: #d28891;
  background-color: #d28891; }

.quina-primary-color {
  fill: #260885;
  background-color: #260885;
  color: #260885; }

.quina-second-color {
  fill: #927fc1;
  background-color: #927fc1; }

.diadesorte-primary-color {
  fill: #cb852b;
  background-color: #cb852b;
  color: #cb852b; }

.diadesorte-second-color {
  fill: #edc48b;
  background-color: #edc48b; }

.lottery-icon {
  font-size: 2.5rem !important; }
